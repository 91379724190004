@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --theme-primary: #efefef;
}
