body {
    padding: 2em;
}
/* .container {
    position: relative;
    height: 60vh;
    width: 60vw;
    background: var(--theme-primary);
    color: #333;
} */

header {
    display: flex;
    align-items: center;
}

header > img {
    margin-right: 5px;
}
.buttonClass {
    transition: background 0.25s, border-color 0.25s;
    background: rgba(40, 44, 52, 0.05);
    border: 1px solid transparent;
    border-radius: 6px;
    color: #3080d0;
    text-decoration: none !important;
    display: inline-block;
    font-size: 0.8rem;
    padding: 8px 16px;
    margin: 0 8px 8px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.graphContainer {
    position: relative;
    margin: auto;
    height: 80vh;
    width: 80vw;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    /* width: 90vw;
    height: 50vh;
    background: white;
    padding: 30px 20px 10px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3); */
}

@media only screen and (max-width: 600px) {
    /* .graphContainer {
        width: 100vw;
    } */
}
